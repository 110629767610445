import gql from 'graphql-tag'
import { updateDependentValues } from './utils'

const types = `
  type ClientReservation {
    id: ID
    guestCount: Int
    name: String
    email: String
    phone: String
    liabilityRelease: Boolean
    accommodations: [Accommodation]
    includedGuestCount: Int
    additionalGuestFee: Int
    totalPrice: Int
    paymentId: String
  }

  extend type Mutation {
    updateReservationInput(
      guestCount: Int,
      name: String,
      email: String,
      phone: String,
      liabilityRelease: Boolean
    ): String
    addRemoveAccommodation(id: ID!): String
  }
`

const defaults = {
  reservationInput: {
    __typename: 'ClientReservation',
    guestCount: '',
    accommodations: [],
    name: '',
    email: '',
    phone: '',
    liabilityRelease: false,
    includedGuestCount: 0,
    additionalGuestFee: 0,
    totalPrice: 0,
    paymentId: null
  }
}

const resolvers = {
  updateReservationInput: (_, args, { cache }) => {
    const query = gql`
      query GetReservationInput {
        reservationInput @client {
          guestCount
          name
          email
          phone
          liabilityRelease
          additionalGuestFee
          totalPrice
          includedGuestCount
          paymentId
        }
      }
    `
    const previous = cache.readQuery({ query })

    const data = {
      reservationInput: {
        ...previous.reservationInput,
        ...args
      }
    }

    cache.writeQuery({ query, data })
    updateDependentValues(cache)
    return null
  },
  addRemoveAccommodation: (_, { id }, { cache }) => {
    // Update Accommodation isReserved property
    const cacheId = `Accommodation:${id}`
    const fragment = gql`
      fragment accommodation on Accommodation {
        id
        name
        price
        includedGuestCount
        isReserved @client
      }
    `
    const previousAcc = cache.readFragment({ id: cacheId, fragment })
    const updatedAcc = { ...previousAcc, isReserved: !previousAcc.isReserved }
    cache.writeFragment({ id: cacheId, fragment, data: updatedAcc })

    // Update List of Accommodations
    const query = gql`
      query {
        reservationInput @client {
          accommodations {
            id
            name
            price
            includedGuestCount
          }
        }
      }
    `
    const { reservationInput } = cache.readQuery({ query })
    const { accommodations } = reservationInput

    const updatedAccommodations = accommodations.map(a => a.id).includes(id)
      ? accommodations.filter(acc => acc.id !== id)
      : [...accommodations, updatedAcc]

    const data = {
      reservationInput: {
        ...reservationInput,
        accommodations: updatedAccommodations
      }
    }

    cache.writeQuery({ query, data })
    updateDependentValues(cache)
    return null
  }
}

export default {
  types,
  resolvers,
  defaults
}
