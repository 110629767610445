import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default () => (
  <Container>
    <Copyright>
      ©2020 Terra Rose Valley Ranch, LLC | All Rights Reserved
    </Copyright>
    <Column>
      <ColumnHeading>Site Map</ColumnHeading>
      <NavItem to="/app/accommodations">Find a Campsite</NavItem>
      <NavItem to="/amenities">Amenities</NavItem>
      <NavItem to="/activities">Activities</NavItem>
      <NavItem to="/app/group-reservation">Group Reservation</NavItem>
      <NavItem to="/about">About</NavItem>
      <NavItem to="/eagle">Eagle Projects</NavItem>
    </Column>
    <Column>
      <ColumnHeading>Location</ColumnHeading>
      <Text>
        Just 2 miles north of the small town of Gravette in Northwest Arkansas.
        And only 15 minutes from the Elk River. Ideal setting for young and old.
        Come and enjoy the Camp Terra Rose Experience.
      </Text>
      {/* <Directions
        blue
        href="https://www.google.com/maps/dir/?api=1&destination=Camp+Terra+Rose%Gravette&Arkansas"
        target="_blank"
      >
        Get Directions
      </Directions> */}
    </Column>
    <Column>
      <ColumnHeading>Campground Info</ColumnHeading>
      <Text>
        Our campground is open year round accommodating groups of all sizes.
      </Text>
      <Text>
        For large group reservations please fill out our{' '}
        <TextLink to="/app/group-reservation">
          Large Group Request form.
        </TextLink>
      </Text>
      <Text>You can also call us at 479-640-4467</Text>
    </Column>
  </Container>
)
const Container = styled.div`
  padding: 50px 30px;
  width: 100%;
  background: #212134;
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: ${props => props.theme.desktop}px) {
    justify-content: space-between;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    flex-direction: column;
  }
`
const Column = styled.div`
  margin: 0 55px 25px;
  width: 20%;

  @media (max-width: ${props => props.theme.xl}px) {
    margin: 0 25px 25px;
    width: 25%;
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    width: calc(33% - 10px);
    margin: 0;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 90%;
    margin: 0 auto 25px;
  }
`
const ColumnHeading = styled.h3`
  font-size: 18px;
  color: ${props => props.theme.fontColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.regular};
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.fontColor};
`
const NavItem = styled(Link)`
  display: block;
  font-size: 12pt;
  line-height: 26px;
  color: ${props => props.theme.fontColor};
  font-weight: ${props => props.theme.regular};
  font-family: ${props => props.theme.secondaryFont};
  transition-duration: 0.5s;

  &:hover {
    color: ${props => props.theme.mainColor};
  }
`
const Text = styled.p`
  margin-bottom: 20px;
  font-size: 12pt;
  line-height: 22px;
  color: ${props => props.theme.fontColor};
  font-weight: ${props => props.theme.light};
  font-family: ${props => props.theme.secondaryFont};
`
const TextLink = styled(Link)`
  font-size: 12pt;
  line-height: 22px;
  color: ${props => props.theme.mainColor};
  font-weight: ${props => props.theme.light};
  font-family: ${props => props.theme.secondaryFont};
`
// const Directions = styled.a`
//   display: block;
//   padding: 25px 30px;
//   border-radius: 25px;
//   font-size: 22px;
//   line-height: 0;
//   text-align: center;
//   font-weight: ${props => props.theme.semiBold};
//   font-family: ${props => props.theme.primaryFont};
//   color: white;
//   transition-duration: 0.3s;
//   cursor: pointer;
//   background-color: ${props => props.theme.mainColor};
//   max-width: 220px;
//
//   &:hover {
//     background-color: #0a6dc3;
//   }
//
//   @media (max-width: ${props => props.theme.phone}px) {
//     padding: 23px 20px;
//     border-radius: 25px;
//     font-size: 20px;
//   }
// `
const Copyright = styled.p`
  font-size: 12px;
  color: ${props => props.theme.fontColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.light};
  position: absolute;
  bottom: 15px;
  left: 30px;
`
