import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { ReactComponent as menu } from 'icons/menu.svg'
import { ReactComponent as logo } from 'icons/main-logo.svg'

import MobileMenu from './MobileMenu'

export default class NavigationBar extends Component {
  state = {
    showMenu: false
  }

  toggleMenu = () => this.setState({ showMenu: !this.state.showMenu })

  render() {
    const { showMenu } = this.state
    return (
      <Fragment>
        {showMenu ? <MobileMenu closeMenu={this.toggleMenu} /> : null}
        <NavBar>
          <Link to="/">
            <MainLogo />
          </Link>
          <Menu onClick={this.toggleMenu} />
          <NavLinks>
            <NavLink to="/app/accommodations">Find a Campsite</NavLink>
            <NavLink to="/app/group-reservation">Group Reservation</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/activities">Activities</NavLink>
            <NavLink to="/amenities">Amenities</NavLink>
            <NavLink to="/eagle">Eagle Projects</NavLink>
          </NavLinks>
        </NavBar>
      </Fragment>
    )
  }
}
const NavBar = styled.nav`
  grid-area: navbar;
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  margin-right: 40px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  border-bottom: solid 1px #d5dceb;
  z-index: 3;

  @media (max-width: ${props => props.theme.tablet}px) {
    position: relative;
    height: 60px;
    padding: 0 20px;
  }
`
const NavLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 70%;
  align-items: center;

  @media (max-width: ${props => props.theme.desktop}px) {
    display: none;
  }
`
const MainLogo = styled(logo)`
  width: 170px;
  height: 40px;
`
const Menu = styled(menu)`
  display: none;
  width: 30px;
  height: 19px;
  cursor: pointer;

  @media (max-width: ${props => props.theme.desktop}px) {
    display: inline;
  }
`
const NavLink = styled(Link)`
  font-size: 18px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  margin-right: 40px;
  text-align: center;

  &:hover {
    color: ${props => props.theme.mainColor};
  }
`
