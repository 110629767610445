import { injectGlobal, css } from 'styled-components'

export default {
  // colors
  mainColor: '#2794F2',
  fontColor: '#565D6F',
  backgroundColor: '#F4F4FA',
  //shadow
  shadow: '0 3px 10px 0 rgba(0, 0, 0, 0.15)',
  // fonts
  mainFont: "'Source Sans Pro'",
  secondaryFont: 'Roboto',
  labelFont: "'Roboto Condensed'",
  waiverTitle: "600 32px/38px 'Source Sans Pro'",
  waiverSubtitle: "600 16px/20px 'Source Sans Pro'",
  waiverText: "400 12px/18px 'Source Sans Pro'",
  //weights
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  black: 900,
  // screen sizes
  xl: 1250,
  desktop: 950,
  tablet: 768,
  phone: 414
}

// Inject reset.css into main style sheet;
export const globalStyles = css`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i|Source+Sans+Pro:400,400i,700,700i,900,900i|Roboto+Condensed:300,300i,400,400i|Dancing+Script:700');

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video,
  input,
  main {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    vertical-align: baseline;
    box-sizing: border-box;
    font-weight: 300;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  main,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  input {
    outline: none;
  }
`

injectGlobal`${globalStyles}`
