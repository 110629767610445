import gql from 'graphql-tag'
import moment from 'moment'
import { updateDependentValues } from './utils'

const types = `
  type PickerDateRange {
    id: ID
    arriveDate: String
    leaveDate: String
    singleDay: Boolean
  }

  type Mutation {
    updatePicker(
      arriveDate: String,
      leaveDate: String,
      singleDay: Boolean
    ): String
  }
`

const defaults = {
  pickerRange: {
    __typename: 'DateRange',
    arriveDate: '',
    leaveDate: '',
    singleDay: false,
    duration: 0
  }
}

const resolvers = {
  updatePicker: (_, { arriveDate, leaveDate }, { cache }) => {
    const query = gql`
      query GetPickerRange {
        reservationInput @client {
          accommodations {
            id
            name
          }
        }
        pickerRange @client {
          arriveDate
          leaveDate
        }
      }
    `

    const previous = cache.readQuery({ query })

    const newArriveDate = arriveDate
      ? moment(arriveDate).format('YYYY-MM-DD')
      : ''

    const newLeaveDate = leaveDate ? moment(leaveDate).format('YYYY-MM-DD') : ''

    const data = {
      pickerRange: {
        ...previous.pickerRange,
        arriveDate: newArriveDate,
        leaveDate: newLeaveDate
      },
      reservationInput: {
        ...previous.reservationInput,
        accommodations: []
      }
    }

    cache.writeQuery({ query, data })
    updateDependentValues(cache)
    return null
  },
  toggleSingleDay: (_, args, { cache }) => {
    const query = gql`
      query GetPickerRange {
        pickerRange @client {
          arriveDate
          leaveDate
          singleDay
        }
      }
    `
    const previous = cache.readQuery({ query })

    const data = {
      pickerRange: {
        ...previous.pickerRange,
        leaveDate: previous.pickerRange.arriveDate,
        singleDay: !previous.pickerRange.singleDay
      }
    }

    cache.writeQuery({ query, data })
    return null
  }
}

export default {
  types,
  resolvers,
  defaults
}
