import gql from 'graphql-tag'

const types = `
  type ClientGroupReservation {
    guestCount: Int
    contactName: String
    contactEmail: String
    contactPhone: String
    groupName: String
    reservationPurpose: String
    activities: [String]
    additionalInfo: String
  }
  extend type Mutation {
    updateGroupReservationInput(
      guestCount: Int,
      contactName: String,
      contactEmail: String,
      contactPhone: String,
      groupName: String,
      reservationPurpose: String,
      activities: [String],
      additionalInfo: String
    ): String
  }
`

const defaults = {
  groupReservationInput: {
    __typename: 'ClientGroupReservation',
    guestCount: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    groupName: '',
    reservationPurpose: '',
    activities: [],
    additionalInfo: ''
  }
}

const resolvers = {
  updateClientGroupReservation: (_, args, { cache }) => {
    const query = gql`
      query {
        groupReservationInput @client {
          guestCount
          contactName
          contactEmail
          contactPhone
          groupName
          reservationPurpose
          activities
          additionalInfo
        }
      }
    `
    const previous = cache.readQuery({ query })

    const data = {
      groupReservationInput: {
        ...previous.groupReservationInput,
        ...args
      }
    }

    cache.writeQuery({ query, data })
    return null
  }
}

export default {
  types,
  resolvers,
  defaults
}
