import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'

import { ReactComponent as X } from 'icons/x-icon.svg'

export default class MobileMenu extends Component {
  render() {
    const { closeMenu } = this.props
    return (
      <Fragment>
        <OutsideMenu onClick={closeMenu} />
        <Container>
          <Close onClick={closeMenu} />
          <NavLinks>
            <Links>
              <NavLink to="/">Home</NavLink>
              <NavLink to="/app/accommodations">Find a Campsite</NavLink>
              <NavLink to="/app/group-reservation">Group Reservation</NavLink>
              <NavLink to="/about">About</NavLink>
              <NavLink to="/activities">Activities</NavLink>
              <NavLink to="/amenities">Amenities</NavLink>
              <NavLink to="/eagle">Eagle Projects</NavLink>
            </Links>
          </NavLinks>
        </Container>
      </Fragment>
    )
  }
}

const Container = styled.div`
  width: calc(100% - 100px);
  max-width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  animation: menuSlide 0.5s;
  background: white;
  z-index: 3000;

  @keyframes menuSlide {
    from {
      width: 0;
    }
    to {
      width: calc(100vw - 100px);
    }
  }
`
const OutsideMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100vh;
  z-index: 2999;
  animation: outsideFade 0.5s;

  @keyframes outsideFade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
const Close = styled(X)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  animation: strokeAnimation 0.3s;

  @keyframes strokeAnimation {
    from {
      transform: rotate(90deg) scale(0);
    }
    to {
      transform: rotate(0deg) scale(1);
    }
  }

  path {
    stroke-width: 2pt;
    stroke-linecap: round;
    stroke: ${props => props.theme.mainColor};
  }
`
const NavLinks = styled.div`
  width: calc(100vw - 100px);
  max-width: 300px;
  height: 100%;
  animation: navFade 1s;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @keyframes navFade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
const Links = styled.div`
  margin: 20px 40px;
  width: calc(100vw - 150px);
  max-width: 250px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

const NavLink = styled(Link)`
  margin: 10px 0;
  font-size: 20px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  margin-right: 40px;
`
// const Directions = styled.a`
//   margin: 10px 0;
//   font-size: 20px;
//   font-family: ${props => props.theme.secondaryFont};
//   font-weight: ${props => props.theme.bold};
//   color: ${props => props.theme.fontColor};
//   margin-right: 40px;
// `
