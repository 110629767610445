import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { ApolloProvider } from 'react-apollo'
import { StripeProvider } from 'react-stripe-elements-universal'
import theme from 'theme'
import { client } from 'apollo'

export default ({ children }) => (
  <ThemeProvider theme={theme}>
    <StripeProvider apiKey={process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}>
      <ApolloProvider client={client}>
        <StaticQuery
          query={graphql`
            query LayoutQuery {
              site {
                siteMetadata {
                  title
                }
              }
              favicon: imageSharp(original: { src: { regex: "/favicon/" } }) {
                fixed(width: 33) {
                  src
                }
              }
            }
          `}
          render={data => (
            <Helmet>
              <title>{data.site.siteMetadata.title}</title>
              <link rel="icon" href={data.favicon.fixed.src} />
            </Helmet>
          )}
        />
        {children}
      </ApolloProvider>
    </StripeProvider>
  </ThemeProvider>
)
