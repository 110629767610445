import moment from 'moment'
import gql from 'graphql-tag'

function getIncludedGuestCount(accommodations) {
  return accommodations.reduce((count, accom) => {
    return count + accom.includedGuestCount
  }, 0)
}

function getDuration(arriveDate, leaveDate) {
  if (!arriveDate || !leaveDate) return 1

  const arrive = moment(arriveDate)
  const leave = moment(leaveDate)
  return leave.diff(arrive, 'days')
}

function getTotalAccommodationFee(accommodations) {
  return accommodations.reduce((price, accom) => {
    return price + accom.price
  }, 0)
}

function getTotalGuestFee(totalGuests, includedGuests) {
  return includedGuests < totalGuests ? (totalGuests - includedGuests) * 3 : 0
}

export function updateDependentValues(cache) {
  const query = gql`
    query {
      reservationInput @client {
        guestCount
        additionalGuestFee
        totalPrice
        includedGuestCount
        accommodations {
          id
          name
          price
          includedGuestCount
        }
      }
      pickerRange @client {
        arriveDate
        leaveDate
      }
    }
  `
  const previous = cache.readQuery({ query })
  const { reservationInput, pickerRange } = previous
  const { accommodations, guestCount } = reservationInput

  const includedGuestCount = getIncludedGuestCount(accommodations)
  const duration = getDuration(pickerRange.arriveDate, pickerRange.leaveDate)
  const totalAccommodationFee = getTotalAccommodationFee(accommodations)
  const additionalGuestFee = getTotalGuestFee(guestCount, includedGuestCount)

  const totalPerDay = totalAccommodationFee + additionalGuestFee
  const totalPrice = totalPerDay * duration

  const data = {
    reservationInput: {
      ...reservationInput,
      includedGuestCount,
      additionalGuestFee,
      totalPrice
    },
    pickerRange
  }

  cache.writeQuery({ query, data })
}
