import gql from 'graphql-tag'
import ClientReservation from './ClientReservation'
import ClientGroupReservation from './ClientGroupReservation'
import PickerRange from './DateRange'

const rootTypes = `
  type Query {
    pickerRange: PickerDateRange
    reservationInput: ClientReservation
    groupReservationInput: ClientGroupReservation
  }
`

export const typeDefs = [
  rootTypes,
  ClientReservation.types,
  ClientGroupReservation.types,
  PickerRange.types
]

export const defaults = {
  ...ClientReservation.defaults,
  ...ClientGroupReservation.defaults,
  ...PickerRange.defaults
}

export const resolvers = {
  Mutation: {
    ...ClientReservation.resolvers,
    ...ClientGroupReservation.resolvers,
    ...PickerRange.resolvers
  },
  Accommodation: {
    isReserved: (accommodation, args, { cache }) => {
      const query = gql`
        query GetReservationInput {
          reservationInput @client {
            accommodations
          }
        }
      `

      const { reservationInput } = cache.readQuery({ query })
      const index = reservationInput.accommodations.findIndex(
        acc => acc.id === `Accommodation:${accommodation.id}`
      )
      return index !== -1
    }
  }
}
