import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { withClientState } from 'apollo-link-state'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'

import ErrorLink from './error-link'
import { defaults, resolvers, typeDefs } from './state-link'
import HttpLink from './http-link'

const cache = new InMemoryCache()

const StateLink = withClientState({ cache, defaults, resolvers, typeDefs })

if (typeof window !== 'undefined') {
  persistCache({ cache, storage: window.localStorage })
}

const client = new ApolloClient({
  link: ApolloLink.from([ErrorLink, StateLink, HttpLink]),
  cache
})

client.onResetStore(StateLink.writeDefaults)

export { client }
