import React from 'react'
import Layout from 'components/Layout'
import Navbar from 'components/Navbar'

export default ({ children }) => (
  <Layout>
    <Navbar />
    {children}
  </Layout>
)
